@import '../../Partials-scss/config';
// .footer {
// 	position: relative;
// 	bottom: 0;
// 	left: 0;
// 	// margin-left: 0%;
// }
// .footerLine {
// 	position: relative;
// 	bottom: 0;
// 	width: 100%;
// 	align-items: center;
// 	left: 0;
// 	padding-bottom: 30px;
// 	padding-left: 22vw;
// 	padding-right: 22vw;
// }
// .footerMain {
//     position: relative;
//     width: 60vw;
//     bottom: 0;
//     margin-bottom: 20px;
// }
@include media-web {
    .footer {
        position: relative;
        bottom: -3.5rem;
        left: 0;
        margin-left: 1%;
        text-align: center;
        right: 1rem;
        padding: 1rem;
        background-color: #f2f2f2;
        height: 100px;
        width: 80vw;
        margin: 0 auto;
    }
}

@include media-mobile {
    .footer {
        position: relative;
        bottom: -3.5rem;
        margin-left: 1%;
        text-align: center;
        right: -1rem;
        padding: 1rem;
        background-color: #f2f2f2;
        height: 100px;
        width: 100vw;
        margin: 0 auto;
    }
}

.footerLine {
    position: relative;
    bottom: -3.5rem;
    width: 100%;
    align-items: center;
    left: 0;
    padding-bottom: 30px;
    padding-left: 22vw;
    padding-right: 22vw;
}

.img {
    width: 20px;
}