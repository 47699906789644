@mixin media-mobile {
    @media screen and (max-width: 600px) {
        @content;
    }
}

@mixin media-web {
    @media screen and (min-width: 600px) {
        @content;
    }
}