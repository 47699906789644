@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Firefox < 16 */

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Internet Explorer */

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Opera < 12.1 */

@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes blendin {
    from {
        background-color: #243139;
    }
    to {
        background-color: white;
    }
}


/* Firefox < 16 */

@-moz-keyframes blendin {
    from {
        background-color: #243139;
    }
    to {
        background-color: white;
    }
}


/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes blendin {
    from {
        background-color: #243139;
    }
    to {
        background-color: white;
    }
}


/* Internet Explorer */

@-ms-keyframes blendin {
    from {
        background-color: #243139;
    }
    to {
        background-color: white;
    }
}


/* Opera < 12.1 */

@-o-keyframes blendin {
    from {
        background-color: #243139;
    }
    to {
        background-color: white;
    }
}