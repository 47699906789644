@import './config';
body {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // min-height: 100vh;
    // background-color: black;
}

@include media-web {
    .heading {
        position: relative;
        margin: auto;
        height: 1.2em;
        color: transparent;
        font-family: 'Cambay', sans-serif;
        font-size: 10vw;
        font-weight: 700;
        letter-spacing: -0.02em;
        line-height: 1.03em;
    }
    .heading>div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // color: #ffff64;
        color: black;
        pointer-events: none;
    }
    .heading>div:nth-child(1) {
        -webkit-mask-image: linear-gradient(black 25%, transparent 25%);
        mask-image: linear-gradient(black 25%, transparent 25%);
        animation: deconstructed1 15s infinite;
    }
    .heading>div:nth-child(2) {
        -webkit-mask-image: linear-gradient(transparent 25%, black 25%, black 50%, transparent 50%);
        mask-image: linear-gradient(transparent 25%, black 25%, black 50%, transparent 50%);
        animation: deconstructed2 15s infinite;
    }
    .heading>div:nth-child(3) {
        -webkit-mask-image: linear-gradient(transparent 50%, black 50%, black 75%, transparent 75%);
        mask-image: linear-gradient(transparent 50%, black 50%, black 75%, transparent 75%);
        animation: deconstructed3 15s infinite;
    }
    .heading>div:nth-child(4) {
        -webkit-mask-image: linear-gradient(transparent 75%, black 75%);
        mask-image: linear-gradient(transparent 75%, black 75%);
        animation: deconstructed4 15s infinite;
    }
    @keyframes deconstructed1 {
        0% {
            transform: translateX(100%);
        }
        26% {
            transform: translateX(0%);
        }
        83% {
            transform: translateX(-0.1%);
        }
        100% {
            transform: translateX(-120%);
        }
    }
    @keyframes deconstructed2 {
        0% {
            transform: translateX(100%);
        }
        24% {
            transform: translateX(0.5%);
        }
        82% {
            transform: translateX(-0.2%);
        }
        100% {
            transform: translateX(-125%);
        }
    }
    @keyframes deconstructed3 {
        0% {
            transform: translateX(100%);
        }
        22% {
            transform: translateX(0%);
        }
        81% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-130%);
        }
    }
    @keyframes deconstructed4 {
        0% {
            transform: translateX(100%);
        }
        20% {
            transform: translateX(0%);
        }
        80% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-135%);
        }
    }
}

@include media-mobile {
    .heading>div:nth-child(1) {
        display: none;
    }
    .heading>div:nth-child(2) {
        display: none;
    }
    .heading>div:nth-child(3) {
        display: none;
    }
    .heading>div:nth-child(4) {
        display: none;
    }
}