.main {
    background-color: #243139;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: 0;
    text-decoration: none;
    outline: none;
}

// For mozilla firebox only
@-moz-document url-prefix() {
    .pre {
        background-color: #252f3a;
    }
}

.pre {
    background-color: #1f2d36;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: 0;
    text-decoration: none;
    outline: none;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
    // background-color: #243139; /* to check if color switch work here */
}

.launch {
    position: relative;
    margin-right: 70px;
    float: right;
    margin-top: -60px;
    height: 2.5rem;
    padding: 5px 30px;
    font-size: 24px;
    font-family: monospace;
    text-align: center;
    // cursor: url('./image/cursor.jpg') 4 12, pointer;
    outline: none;
    color: #fff;
    background-color: #2a91a3;
    border: none;
    border-radius: 15px;
    box-shadow: 0 5px #999;
    @media only screen and (max-width: 600px) {
        padding: 2px 9px;
        bottom: 0;
        margin-bottom: 90px;
        margin-left: 40%;
        position: absolute;
    }
}

.launch:hover {
    background-color: #437e4a;
}

.launch:active {
    background-color: #e61313;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}