@import './config';
.cards {
    margin-bottom: 50px;
    display: inline-flex;
    flex: 4;
    flex-wrap: wrap;
    flex-direction: row;
    width: 200px;
    margin-left: 20px;
    .card {
        height: 500px;
        font-weight: 200;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        transition: all 0.3s;
    }
    &:hover {
        .card {
            // letter-spacing: 2px;
            font-weight: 500;
            font-style: italic;
            cursor: pointer;
            -webkit-transform: rotatex(360deg);
            -moz-transform: rotatex(360deg);
            -ms-transform: rotatex(360deg);
            transform: rotatex(360deg);
        }
    }
}

.demoBtn,
.githubBtn {
    width: 100%;
}

.buttons {
    bottom: 0;
    position: absolute;
}

.cardImg {
    width: 100;
    position: absolute;
}

@include media-web {
    .instruct {
        display: none;
    }
}

@include media-mobile {
    .instruct {
        font-size: 15px;
        text-align: center;
        margin-top: -40px;
        margin-bottom: 40px;
    }
    .cards {
        margin-bottom: 50px;
        display: block;
        &:hover {
            .card {
                .cardImg {
                    display: none;
                    position: absolute;
                }
                .body {
                    display: block;
                    position: absolute;
                }
            }
        }
        .card {
            height: 300px;
            width: 80vw;
            .cardImg {
                display: block;
                position: absolute;
            }
            .body {
                display: none;
                position: absolute;
            }
        }
    }
    .cardBody {
        position: absolute;
        bottom: 0;
    }
}