@import '../../Partials-scss/config';
@import '../../Partials-scss/cards';
@import '../../Partials-scss/headingAnimation';
.main {
    margin-top: 20px;
    // position: relative;
}

.title {
    position: absolute;
    left: 0;
    margin-left: 100px;
}

.icon {
    font-size: 20px;
    margin-right: 20px;
}

.heading {
    text-align: center;
    margin-bottom: 100px;
    font-size: 100px;
    font-weight: 200;
}

.scrollTopBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    margin-bottom: 150px;
    margin-right: 60px;
}

.comingSoon {
    color: gray;
    margin-bottom: 35vh;
}

@include media-mobile {
    .main {
        margin-right: 20px;
        width: 90%;
    }
    .title {
        position: absolute;
        left: 0;
        margin-left: 10px;
    }
    .heading {
        font-size: 50px;
    }
}

.demoBtn {
    width: 230%;
    margin-bottom: 20px
}