.topic {
    padding: 12px;
    margin-left: 50px;
}

.btn {
    width: 200px;
}

// #names{
// 	// -webkit-transform: rotatex(360deg);
// 	// -moz-transform: rotatex(360deg);
// 	// -ms-transform: rotatex(360deg);
// 	// transform: rotatex(360deg);
//   -webkit-animation: movement 25s; /* Safari, Chrome and Opera > 12.1 */
// 	-moz-animation: movement 25s; /* Firefox < 16 */
// 	-ms-animation: movement 25s; /* Internet Explorer */
// 	-o-animation: movement 25s; /* Opera < 12.1 */
// 	animation: movement 25s;
// }
// @keyframes movement {
//   from {
// 		background-color: #243139;
// 	}
// 	to {
// 		background-color: white;
// 	}
// }
// /* Firefox < 16 */
// @-moz-keyframes movement {
// from {
// 		background-color: #243139;
// 	}
// 	to {
// 		background-color: white;
// 	}
// }
// /* Safari, Chrome and Opera > 12.1 */
// @-webkit-keyframes movement {
// from {
// 		background-color: #243139;
// 	}
// 	to {
// 		background-color: white;
// 	}
// }
// /* Internet Explorer */
// @-ms-keyframes movement {
// 	from {
// 		background-color: #243139;
// 	}
// 	to {
// 		background-color: white;
// 	}
// }
// /* Opera < 12.1 */
// @-o-keyframes movement {
//   from {
// 		background-color: #243139;
// 	}
// 	to {
// 		background-color: white;
// 	}
// }