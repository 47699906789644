@import '../../Partials-scss/config';
@import '../../Partials-scss/homeAnimation';
@include media-mobile {
    #test {
        // min-height: 200vh;
        width: 110vw;
        margin-left: 10px;
    }
}

@include media-web {
    .subLinks {
        // display: flex;
        // align-items: center;
        // text-align: center;
        // position: absolute;
        // position: relative;
        // margin: 0 auto;
        // margin-left: 28vw;
        // margin-left: 34vw;
        float: right;
    }
}

@include media-mobile {
    .subLinks {
        // text-align: center;
        // position: absolute;
        // margin: 0 auto;
        margin-left: 10vw;
    }
}

#test {
    position: absolute;
    top: 0px;
    left: -12vw;
    // -webkit-animation: blendin 15s; /* Safari, Chrome and Opera > 12.1 */
    // -moz-animation: blendin 15s; /* Firefox < 16 */
    // -ms-animation: blendin 15s; /* Internet Explorer */
    // -o-animation: blendin 15s; /* Opera < 12.1 */
    // animation: blendin 15s;
    h4 {
        margin-top: 25px;
        font-size: 150px;
        text-align: center;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 100;
        font-style: unset;
        font-stretch: unset;
        transition: all 7s ease;
        // display: block;
        color: black;
        @include media-mobile {
            font-size: 100px;
        }
        // -webkit-animation: fadein 25s; /* Safari, Chrome and Opera > 12.1 */
        // -moz-animation: fadein 25s; /* Firefox < 16 */
        // -ms-animation: fadein 25s; /* Internet Explorer */
        // -o-animation: fadein 25s; /* Opera < 12.1 */
        // animation: fadein 25s;
        // &:hover {
        //     // color: rgb(1, 1, 5);
        //     transform: scale(1.3);
        //     // transform: translate(50px, 59px);
        //     // transform: rotate(5deg);
        //     // transform: skewX(10px);
        //     z-index: 27px;
        //     // text-shadow: 3px 3px darkgray;
        //     // font-size: 200px;
        // }
    }
}

.contactLink {
    color: mediumseagreen;
    &:hover {
        color: red;
        text-decoration: none;
    }
}

.spinner {
    display: block;
    margin-left: 45vw;
    margin-right: auto;
    width: 200px;
    height: 200px;
    z-index: 11px;
    position: absolute;
    opacity: 0.1;
    @include media-mobile {
        // width: 31vw;
        // height: 17vh;
        margin-left: 25vw;
    }
}

.image {
    width: 20px;
}

.topic {
    padding: 12px;
    margin-left: 50px;
}

.intro {
    height: 200px;
    position: relative;
    margin-bottom: 20px;
    width: 50%;
    margin: 0 auto;
    text-align: justify;
    @include media-mobile {
        height: 500px;
        width: 80vw;
        margin-left: 50px;
        font-size: 14px;
    }
}

.scrollTopBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    margin-bottom: 150px;
    margin-right: 60px;
}

.content {
    padding-left: 22vw;
    padding-right: 22vw;
    font-weight: 300;
    font-size: 16px;
    font-style: normal;
    line-height: 1.7em;
    letter-spacing: 0px;
    position: relative;
    max-height: 100vh;
    @include media-mobile {
        padding-left: 20px;
        padding-right: 20px;
        // margin-left: 30px;
    }
    // @media screen and (min-width: 1900px) {
    //     padding-left: 22vw;
    //     padding-right: 22vw;
    // }
    // -webkit-animation: fadein 25s; /* Safari, Chrome and Opera > 12.1 */
    // -moz-animation: fadein 25s; /* Firefox < 16 */
    // -ms-animation: fadein 25s; /* Internet Explorer */
    // -o-animation: fadein 25s; /* Opera < 12.1 */
    // animation: fadein 25s;
}

@include media-mobile {
    .breaks {
        display: none
    }
}

@include media-web {
    .head {
        margin-top: 150px;
    }
    .anchorLink {
        color: white
    }
    // .anchorLink:hover {
    //     color: rgb(1, 1, 5);
    // }
    .linkBtn:hover {
        background-color: white;
        color: rgb(1, 1, 5);
        transform: scale(1.3);
        transform: translate(50px, 59px);
        transform: rotate(5deg);
        transform: skewX(10px);
        // .anchorLink {
        //     color: rgb(1, 1, 5);
        // }
    }
}

@include media-mobile {
    .linkBtn {
        display: none
    }
    .contactLinks {
        display: none
    }
    .wes {
        display: none
    }
}